import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Row,
  Col,
  Stack,
  Form,
  Container,
  Offcanvas,
  Navbar,
  ToggleButton,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { UploadButton, RenameModal, FileTree } from "../components";
import { FileIndex, UserSession } from "../types";
import { filesService } from "../services";
import ConfirmDeleteModal from "../components/confirmDeleteModal";
import MediaFilter from "../components/mediaFilter";

function FilesPage() {
  const [sessionLoaded, setSessionLoaded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<FileIndex>();
  const [fileContent, setFileContent] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>();
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileIndex[]>([]);
  const [filteredList, setFilteredList] = useState<FileIndex[]>([]);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [videoPlayer, setVideoPlayer] = useState<ReactPlayer>();

  const [userSession, setUserSession] = useState<UserSession>({
    autoplay: false,
    playedIndices: [],
    searchText: "",
    selectedFileIndex: -1,
    shuffle: false,
    typeFilter: "all",
    playedSeconds: 0,
  });

  const updateUserSession = async (session: UserSession) => {
    if (sessionLoaded) {
      await filesService.saveUserSession(session);
      setUserSession(session);
      console.log("session", session);
    }
  };

  const refreshFileList = async () => {
    const files = await filesService.listFiles();
    setFileList(files);
  };

  const fetchUserSession = async () => {
    const session = await filesService.getUserSession();
    setUserSession(session);
    setSessionLoaded(true);
  };

  const selectItem = (idx: number, item: FileIndex | undefined) => {
    setSelectedItem(item);
    setShowMobileNav(false);
    updateUserSession({
      ...userSession,
      selectedFileIndex: idx,
      playedSeconds: 0,
    });
  };

  const randomIndex = () => {
    return Math.floor(Math.random() * (filteredList.length - 0 + 1));
  };

  const autoselectNext = (force_shuffle = false) => {
    if (filteredList.length > 0) {
      console.log("shuffle", userSession.shuffle);
      if (force_shuffle || userSession.shuffle) {
        var idx = randomIndex();
        while (userSession.playedIndices.includes(idx)) {
          idx = randomIndex();
        }
        const newItem = filteredList[idx];
        console.log(newItem);
        setSelectedItem(newItem);
        updateUserSession({
          ...userSession,
          selectedFileIndex: idx,
          playedIndices: [...userSession.playedIndices, idx],
          playedSeconds: 0,
        });
      } else {
        const idx = filteredList.indexOf(selectedItem!) + 1;
        const newItem = filteredList[idx];
        updateUserSession({
          ...userSession,
          selectedFileIndex: idx,
          playedSeconds: 0,
        });
        setSelectedItem(newItem);
      }
    }
  };

  useEffect(() => {
    async function getFile() {
      if (selectedItem) {
        if (["video", "image"].indexOf(selectedItem.file_type) >= 0) {
          setFileUrl(await filesService.getFileUrl(selectedItem.file_name));
          setFileContent("");
        } else if (selectedItem.file_type === "text") {
          setFileUrl("");
          setFileContent(await filesService.getFile(selectedItem.file_name));
        } else {
          console.log("unhandled", selectedItem);
        }
      }
    }
    getFile();
  }, [selectedItem]);

  useEffect(() => {
    refreshFileList();
    fetchUserSession();
  }, []);

  useEffect(() => {
    var filteredFiles = fileList;
    if (userSession.typeFilter !== "all") {
      filteredFiles = filteredFiles.filter(
        (x) => x.file_type === userSession.typeFilter
      );
    }
    if (userSession.searchText !== "") {
      filteredFiles = filteredFiles.filter(
        (x) =>
          x.file_name
            .toUpperCase()
            .indexOf(userSession.searchText.toUpperCase()) > -1
      );
    }
    setFilteredList(filteredFiles);
    if (selectedItem === undefined && userSession.selectedFileIndex >= 0) {
      setSelectedItem(filteredFiles[userSession.selectedFileIndex]);
    }
  }, [userSession, fileList]);

  // useEffect(() => {
  //   const handleClose = () => {
  //     if (videoPlayer !== undefined) {
  //       updateUserSession({
  //         ...userSession,
  //         playedSeconds: videoPlayer.getCurrentTime(),
  //       });
  //     }
  //   };
  //   window.addEventListener("beforeunload", handleClose);
  // }, []);

  return (
    <>
      <RenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        currentFileName={selectedItem?.file_name as string}
        refreshFileList={refreshFileList}
      />
      <ConfirmDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        currentFileName={selectedItem?.file_name as string}
        refreshFileList={refreshFileList}
      />
      <Container fluid className="h-100">
        <Row xs="1" className="flex-shrink-0">
          <Navbar expand="sm" expanded={showMobileNav}>
            <Container>
              <Navbar.Brand>{selectedItem?.file_name}</Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-sm`}
                onClick={() => setShowMobileNav(!showMobileNav)}
              />
              <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                  <Form.Control
                    placeholder="Search"
                    style={{ width: "200px" }}
                    value={userSession.searchText}
                    onChange={(e) =>
                      updateUserSession({
                        ...userSession,
                        searchText: e.target.value,
                      })
                    }
                  />
                  <MediaFilter
                    userSession={userSession}
                    updateUserSession={updateUserSession}
                  />
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                  <Container fluid>
                    <Row>
                      <Stack direction="horizontal" gap={2}>
                        <span className="ms-auto" />
                        <ToggleButton
                          type="checkbox"
                          checked={userSession.autoplay}
                          value="1"
                          variant="outline-primary"
                          onClick={() =>
                            updateUserSession({
                              ...userSession,
                              autoplay: !userSession.autoplay,
                            })
                          }
                        >
                          Autoplay
                        </ToggleButton>
                        <ToggleButton
                          type="checkbox"
                          value="1"
                          checked={userSession.shuffle}
                          variant="outline-primary"
                          onClick={() => {
                            if (!userSession.shuffle) {
                              updateUserSession({
                                ...userSession,
                                autoplay: true,
                                shuffle: true,
                              });
                              autoselectNext(true);
                              setShowMobileNav(false);
                            } else {
                              updateUserSession({
                                ...userSession,
                                shuffle: false,
                              });
                            }
                          }}
                        >
                          Shuffle
                        </ToggleButton>
                        <DropdownButton title="Edit">
                          <Dropdown.Item
                            onClick={() => setShowRenameModal(true)}
                          >
                            Rename
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowDeleteModal(true)}
                          >
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                        <UploadButton refreshFileList={refreshFileList} />
                      </Stack>
                    </Row>
                    <Row className="d-inline d-md-none w-100 m-0">
                      <div className="pt-2 px-0">
                        <FileTree
                          fileList={filteredList}
                          selectedItem={selectedItem}
                          setSelectedItem={selectItem}
                        />
                      </div>
                    </Row>
                  </Container>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </Row>
        <Row className="flex-fill h-100" style={{ overflowY: "scroll" }}>
          <Col
            lg="3"
            className="h-100 d-none d-md-inline"
            style={{ overflowY: "scroll" }}
          >
            <Row>
              <Col>
                <Form.Control
                  placeholder="Search"
                  className="m-1 p-1"
                  value={userSession.searchText}
                  onChange={(e) =>
                    updateUserSession({
                      ...userSession,
                      searchText: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg="4">
                <MediaFilter
                  userSession={userSession}
                  updateUserSession={updateUserSession}
                />
              </Col>
            </Row>
            <FileTree
              fileList={filteredList}
              selectedItem={selectedItem}
              setSelectedItem={selectItem}
            />
          </Col>
          <Col className="h-100" xs={true}>
            <Container className="mh-100 mw-100">
              <Row>
                <Col style={{ maxHeight: "80vh" }}>
                  {selectedItem === undefined ? (
                    <div>Nothing selected</div>
                  ) : selectedItem.file_type === "image" ? (
                    <img
                      alt="Fetching..."
                      src={fileUrl}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "scale-down",
                      }}
                    />
                  ) : selectedItem.file_type === "video" ? (
                    <ReactPlayer
                      url={fileUrl}
                      controls
                      playing={userSession.autoplay}
                      onStart={() => {
                        if (videoPlayer !== undefined) {
                          videoPlayer.seekTo(userSession.playedSeconds);
                        }
                      }}
                      onReady={(player: ReactPlayer) => {
                        setVideoPlayer(player);
                        // if (userSession.autoplay) {
                        //   player.seekTo(userSession.playedSeconds);
                        // }
                      }}
                      onPause={() => {
                        if (videoPlayer !== undefined) {
                          const time = videoPlayer.getCurrentTime();
                          updateUserSession({
                            ...userSession,
                            playedSeconds: time,
                          });
                        }
                      }}
                      onEnded={autoselectNext}
                    />
                  ) : selectedItem.file_type === "text" ? (
                    <div className="h-100" style={{ overflowY: "scroll" }}>
                      <h3>{selectedItem.file_name}</h3>
                      <body
                        style={{
                          whiteSpace: "pre-line",
                          paddingBottom: "50px",
                        }}
                      >
                        {fileContent}
                      </body>
                    </div>
                  ) : (
                    <div>{selectedItem.file_name}</div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FilesPage;
